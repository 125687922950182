import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import './FeatureL2.css';
import a from './cloud-done.png';
import b from './TI-DONE.png';
import c from './COLLEGE-DONE.png';
import d from './Disaster.png';
import e from './Managed.png';
import f from './Monitoring.png';
import g from './automation.png';
import h from './IT.png';
import i from './GA.PNG';

const features = [
  {
    icon: 'las la-search',
    background: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
    title: "תכנון ומחקר",
    description: "מחקרים וניתוח נתונים תוך תכנון מושכל ואסטרטגי להצלחה המרבית שלנו",
    delay: 0.2,
    path: "/Consulting"
  },
  {
    icon: 'las la-code',
    background: "linear-gradient(135deg, #FF6B6B 0%, #FF000F 100%)",
    title: "פיתוח",
    description: "אנחנו מתמחים בהפיכת הרעיונות שלכם למציאות באמצעות טכנולוגיות מתקדמות",
    delay: 0.3,
    path: "/Development"
  },
  {
    icon: 'las la-cogs',
    background: "linear-gradient(135deg, #36D1DC 0%, #5B86E5 100%)",
    title: "תפעול",
    description: "אנחנו מנהלים את התפעול ואת הצמיחה של הרעיון שלכם, דואגים למסע חלק וביצועים גבוהים",
    delay: 0.4,
    path: "/Operations"
  },
  {
    icon: 'las la-plug',
    background: "linear-gradient(135deg, #11998e 0%, #38ef7d 100%)",
    title: "אינטגרציות",
    description: "חיבור לפעולות שלך על ידי אינטגרציה חלקה של מערכות שונות, יוצרים תהליך עבודה יעיל ומאורגן",
    delay: 0.5,
    path: "/Operations"
  },
  {
    icon: 'las la-shield-alt',
    background: "linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)",
    title: "אבטחה",
    description: "עם אבטחה בראש סדר העדיפויות שלנו, אנו מתכננים כל נקודת מגע עם שיקול דעת מירבי",
    delay: 0.6,
    path: "/Security"
  },
  {
    icon: 'las la-headset',
    background: "linear-gradient(135deg, #F2994A 0%, #F2C94C 100%)",
    title: "ייעוץ ותמיכה",
    description: "אנחנו משמשים כשותפי ייעוץ ותמיכה, מציעים פתרונות מומחים כשאתם צריכים",
    delay: 0.7,
    path: "/Consulting"
  }
];

const serviceCards = [
  {
    icon: 'las la-cloud-upload-alt',
    title: "שירותי ענן מנוהלים ",
    description: "פתרונות ענן מתקדמים המותאמים לצרכי העסק שלך",
    category: "cloud",
    color: "linear-gradient(135deg, #2563eb 0%, #1e40af 100%)",
    isHighlighted: true,
    features: [
      "תשתיות היברידיות מתקדמות",
      "אבטחת מידע",
      "גיבוי ושרידות מלאים",
      "ביצועים מקסימליים",
      "התאמה אישית לצרכי הארגון",
      "תמיכה טכנית ",
      "ניטור וניהול מרכזי",
      "עדכוני מערכת   "
    ],
    description: `פתרונות ענן היברידיים מתקדמים המשלבים את היתרונות של ענן פרטי וציבורי.

• תשתיות מתקדמות במתקנים באירופה
• אבטחה והגנה מקסימלית על המידע
• גמישות וסקלביליות מלאה
• חיסכון משמעותי בעלויות
• שליטה מלאה בנתונים`
  },
  {
    icon: 'las la-network-wired',
    title: "תשתיות טכנולוגיות",
    description: "תשתיות מתקדמות לביצועים מקסימליים",
    category: "infrastructure",
    color: "linear-gradient(135deg, #F59E0B 0%, #D97706 100%)"
  },
  {
    icon: 'las la-laptop-code',
    title: "תכנון ופיתוח מערכות",
    description: "פיתוח מערכות חכמות בהתאמה אישית",
    category: "development",
    color: "linear-gradient(135deg, #EC4899 0%, #BE185D 100%)"
  },
  {
    icon: 'las la-shield-virus',
    title: "שירותי התאוששות מאסון",
    description: "הגנה מקיפה על המידע והמערכות שלך",
    category: "disaster-recovery",
    color: "linear-gradient(135deg, #10B981 0%, #059669 100%)"
  },
  {
    icon: 'las la-sliders-h',
    title: "שירותים מנוהלים",
    description: "ניהול מקצועי של כל השירותים הטכנולוגיים",
    category: "managed-services",
    color: "linear-gradient(135deg, #8B5CF6 0%, #6D28D9 100%)"
  },
  {
    icon: 'las la-analytics',
    title: "שירותי ניטור ובקרה",
    description: "מעקב בזמן אמת אחר ביצועי המערכות",
    category: "monitoring",
    color: "linear-gradient(135deg, #3B82F6 0%, #1D4ED8 100%)"
  },
  {
    icon: 'las la-brain',
    title: "אוטומציות",
    description: "פתרונות אוטומציה חכמים לייעול תהליכים",
    category: "automation",
    color: "linear-gradient(135deg, #14B8A6 0%, #0F766E 100%)"
  },
  {
    icon: 'las la-terminal',
    title: "שירותי IT",
    description: "תמיכה מקיפה בכל צרכי ה-IT שלך",
    category: "it-services",
    color: "linear-gradient(135deg, #F472B6 0%, #DB2777 100%)"
  },
  {
    icon: 'las la-layer-group',
    title: "פיתוח תוכנה SaaS",
    description: `פיתוח תוכנה מתקדם המותאם במיוחד לצרכים העסקיים שלך.

• פיתוח פלטפורמות בענן מתקדמות
• ממשקי משתמש חדשניים ואינטואיטיביים
• אינטגרציה מלאה עם מערכות קיימות
• אבטחה ברמה הגבוהה ביותר
• תמיכה ותחזוקה שוטפת `,
    category: "saas",
    color: "linear-gradient(135deg, #7c3aed 0%, #4c1d95 100%)",
    isHighlighted: true,
    features: [
      "התאמה אישית לצרכי העסק",
      "תמיכה טכנית",
      "אחסון וגיבוי בענן",
      "אבטחת מידע מתקדמת",
      "עדכונים ושדרוגים שוטפים",
      "ניטור ביצועים בזמן אמת",
      "ממשק ניהול מתקדם"
    ]
  }
];

function FeatureL2he() {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCard, setActiveCard] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setIsVisible(true);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Optimize animation variants for mobile
  const cardVariants = {
    hidden: isMobile ? { opacity: 1 } : { opacity: 0, y: 20 },
    visible: isMobile ? { opacity: 1 } : { opacity: 1, y: 0 }
  };

  const featureVariants = {
    hidden: isMobile ? { opacity: 1 } : { opacity: 0, x: -10 },
    visible: isMobile ? { opacity: 1 } : { opacity: 1, x: 0 }
  };

  return (
    <div className="modern-feature-section rtl-section">
      {/* Platform Announcement */}
      <motion.div 
        className="platform-announcement"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Container>
          <div className="announcement-content">
            <div className="announcement-icon">
              <i className="las la-cloud"></i>
            </div>
            <div className="announcement-text">
              <motion.span
                className="new-badge"
                initial={{ scale: 1 }}
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 2, repeat: Infinity, repeatDelay: 3 }}
              >
                חדש!
              </motion.span>
              <span className="announcement-message">
                שירותי ענן היברידי זמינים כעת בשני מתקנים באירופה
              </span>
            </div>
            <motion.div
            >
            </motion.div>
          </div>
        </Container>
      </motion.div>

      {/* Hero Section */}
      <motion.div 
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="hero-content"
              >
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3, duration: 0.6 }}
                  className="hero-badge"
                >
                  <span>🚀 מובילים חדשנות</span>
                </motion.div>
                
                <motion.h1 
                  className="hero-title"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.8 }}
                >
                  <span className="gradient-text">חדשנות דיגיטלית</span>{' '}
                  <br />
                  <span>בעידן המודרני</span>
                </motion.h1>

                <motion.p 
                  className="hero-description"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.7, duration: 0.8 }}
                >
                  <span className="highlight-text"> מובילים את המהפכה הטכנולוגית.</span>{' '}
                   אנחנו מקדמים יזמים, סטארטאפים ובעלי עסקים להצלחה בעולם הדיגיטלי המתפתח.  
                  <br /><br />
                  <span className="emphasis-text"> פתרונות תוכנה מקצה לקצה</span>, 
                  {' '}
                  <span className="gradient-text" style={{background: "linear-gradient(135deg, #FF1493 0%, #9400D3 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>החזון שלך</span>, <span className="gradient-text" style={{background: "linear-gradient(135deg, #32CD32 0%, #008000 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>המומחיות שלנו</span> – 
                  <span className="bold-text">בוא נצמח יחד.</span>
                </motion.p>
                
                <motion.div 
                  className="hero-buttons"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Link to="https://boarding.epicfinal.com/login" className="primary-button">
                    <motion.span
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="button-content"
                    >
                    התחל עכשיו
                    <i className="las la-arrow-left"></i>
                    </motion.span>
                  </Link>
                  <Link to="https://meetings.hubspot.com/viacheslav-seifer" className="secondary-button">
                    <motion.span
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="button-content"
                    >
                      קבל יעוץ חינם
                    <i className="las la-info-circle"></i>
                    </motion.span>
                  </Link>
                </motion.div>

                <motion.div 
                  className="hero-stats"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.1, duration: 0.8 }}
                >
                  
                </motion.div>
              </motion.div>
            </Col>
            <Col lg={6}>
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="hero-image-wrapper"
              >
                <motion.div
                  className="hero-image-container"
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                >
                  <motion.div className="floating-elements">
                    <motion.div
                      className="float-element code"
                      animate={{ y: [-10, 10, -10] }}
                      transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
                    >
                      <i className="las la-code"></i>
                    </motion.div>
                    <motion.div
                      className="float-element cloud"
                      animate={{ y: [10, -10, 10] }}
                      transition={{ duration: 3.5, repeat: Infinity, ease: "easeInOut" }}
                    >
                      <i className="las la-cloud"></i>
                    </motion.div>
                    <motion.div
                      className="float-element rocket"
                      animate={{ y: [-15, 15, -15] }}
                      transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
                    >
                      <i className="las la-rocket"></i>
                    </motion.div>
                  </motion.div>
                  <div className="cicd-visualization-modern">
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-code"></i>
                        <span>פיתוח</span>
                      </div>
                      <div className="connection-line"></div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-vial"></i>
                        <span>בדיקות</span>
                      </div>
                      <div className="connection-line"></div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-box"></i>
                        <span>בנייה</span>
                      </div>
                      <div className="connection-line"></div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.8 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-rocket"></i>
                        <span>הטמעה</span>
                      </div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-progress"
                      initial={{ width: "0%" }}
                      animate={{ width: "100%" }}
                      transition={{ duration: 2, delay: 1, ease: "easeInOut" }}
                    />
                  </div>
                  
                  
                </motion.div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.div>

      {/* Features Grid */}
      <section className="features-section">
        <Container>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="section-header text-center"
          >
            <h2 className="section-title">הפתרונות שלנו</h2>
            <p className="section-subtitle">גלה את מגוון השירותים המתקדמים שלנו</p>
          </motion.div>

          <div className="features-grid">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="modern-feature-card"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -10, transition: { duration: 0.2 } }}
              >
                <Link to={feature.path}>
                  <div className="feature-icon" style={{ background: feature.background }}>
                    <i className={feature.icon}></i>
                  </div>
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                  <div className="feature-arrow">
                    <i className="las la-arrow-left"></i>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </Container>
      </section>

      {/* Platform Onboarding */}
      <section className="platform-onboarding-section">
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="platform-onboarding"
          >
            <h3 className="text-center">תהליך העבודה שלנו</h3>
            <div className="onboarding-steps">
              <div className="step">
                <div className="step-number">1</div>
                <div className="step-icon">🚀</div>
                <h4>צור פרויקט חדש</h4>
                <p>התחל מיד, בקלות ובמהירות</p>
              </div>
              
              <div className="step">
                <div className="step-number">2</div>
                <div className="step-icon">🎯</div>
                <h4>בחר את המסלול שלך</h4>
                <p>קבל ייעוץ מומחה או התחל בהערכה חכמה</p>
              </div>
              
              <div className="step">
                <div className="step-number">3</div>
                <div className="step-icon">📅</div>
                <h4>קבע פגישה</h4>
                <p>נתאם יחד את המטרות והדרישות</p>
              </div>
              
              <div className="step">
                <div className="step-number">4</div>
                <div className="step-icon">📊</div>
                <h4>עקוב אחר ההתקדמות</h4>
                <p>צפה בכל שלב דרך לוח הבקרה שלך</p>
              </div>
              
              <div className="step">
                <div className="step-number">5</div>
                <div className="step-icon">🎉</div>
                <h4>השלמת הפרויקט</h4>
                <p>קבל את קוד המקור או האישורים בקלות</p>
              </div>
            </div>
            
            <div className="platform-actions">
              <a href="https://boarding.epicfinal.com/register" 
                 className="platform-btn primary"
                 target="_blank"
                 rel="noopener noreferrer">
                <span className="button-text">התחל עכשיו בפלטפורמה</span>
                <i className="las la-arrow-left"></i>
              </a>
              <a href="https://meetings.hubspot.com/viacheslav-seifer" 
                 className="platform-btn green"
                 target="_blank"
                 rel="noopener noreferrer">
                <span className="button-text">קבע פגישה</span>
                <i className="las la-calendar"></i>
              </a>
            </div>
          </motion.div>
        </Container>
      </section>

      {/* Services Showcase - New Design */}
      <section className="services-showcase">
        <Container>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            className="section-header text-center mb-5"
          >
            <h2 className="section-title">הפתרונות שלנו</h2>
            <p className="section-subtitle">מגוון פתרונות טכנולוגיים מתקדמים</p>
          </motion.div>

          {/* Highlighted Cards Container */}
          <div className="highlighted-cards-container">
            {serviceCards
              .filter(service => service.isHighlighted)
              .map((service, index) => (
                <motion.div
                  key={index}
                  className="modern-service-card highlighted-card"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={cardVariants}
                  transition={{ duration: isMobile ? 0 : 0.3 }}
                  style={{ 
                    background: service.color,
                    transform: isMobile ? 'none' : undefined,
                    willChange: isMobile ? 'auto' : 'transform'
                  }}
                >
                  <div className="service-card-content">
                    <div className="service-icon">
                      <i className={service.icon}></i>
                    </div>
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                    <div className="feature-list">
                      {service.features.map((feature, idx) => (
                        <motion.div
                          key={idx}
                          className="feature-item"
                          variants={featureVariants}
                          initial="hidden"
                          whileInView="visible"
                          transition={{ 
                            duration: isMobile ? 0 : 0.2,
                            delay: isMobile ? 0 : idx * 0.05 
                          }}
                          style={{
                            willChange: isMobile ? 'auto' : 'transform',
                            transform: isMobile ? 'none' : undefined
                          }}
                        >
                          <i className="las la-check-circle"></i>
                          <span>{feature}</span>
                        </motion.div>
                      ))}
                    </div>
                    <div className="saas-actions">
                      <Link to="/Pricing-Calculator" className="service-link primary">
                        מחשבון עלויות
                        <i className="las la-calculator"></i>
                      </Link>
                      <Link to="/contact1" className="service-link secondary">
                        צור קשר לייעוץ
                        <i className="las la-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                </motion.div>
              ))}
          </div>

          {/* Regular Service Cards */}
          <div className="modern-services-grid">
            {serviceCards
              .filter(service => !service.isHighlighted)
              .map((service, index) => (
                <motion.div
                  key={index}
                  className="modern-service-card"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ y: -10, scale: 1.02 }}
                  style={{ background: service.color }}
                >
                  <div className="service-card-content">
                    <div className="service-icon">
                      <i className={service.icon}></i>
                    </div>
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </Container>
      </section>

      {/* Call to Action */}
      <motion.section
        className="cta-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        <Container>
          <div className="cta-content">
            <h2>מוכנים להתחיל?</h2>
            <p>צור קשר עוד היום ונתחיל לבנות את העתיד הדיגיטלי שלך</p>
            <div className="button-container">
              <a 
                href="https://boarding.epicfinal.com" 
                className="cta-button primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                התחל עכשיו
                <i className="las la-rocket ms-1"></i>
              </a>
              <a 
                href="https://meetings.hubspot.com/viacheslav-seifer" 
                className="cta-button secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                קבע פגישה
                <i className="las la-calendar ms-1"></i>
              </a>
            </div>
          </div>
        </Container>
      </motion.section>
    </div>
  );
}

export default FeatureL2he;
