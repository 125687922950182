import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './HerosectionL4.css';

function HerosectionL4() {
  const [isVisible, setIsVisible] = useState(false);
  const [activeFeature, setActiveFeature] = useState(0);

  useEffect(() => {
    setIsVisible(true);
    const interval = setInterval(() => {
      setActiveFeature(prev => (prev + 1) % features.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const features = [
    {
      title: "Cloud Solutions",
      description: "Scalable infrastructure for your growing business",
      icon: "las la-cloud",
      color: "#4285F4"
    },
    {
      title: "Security First",
      description: "Enterprise-grade protection for your data",
      icon: "las la-shield-alt",
      color: "#34A853"
    },
    {
      title: "Support & Consulting",
      description: "Round-the-clock expert assistance",
      icon: "las la-headset",
      color: "#FBBC04"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <motion.section 
      className="hero-section"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="hero-background">
        <div className="gradient-sphere gradient-sphere-1"></div>
        <div className="gradient-sphere gradient-sphere-2"></div>
        <div className="gradient-sphere gradient-sphere-3"></div>
      </div>

      <div className="container position-relative">
        <div className="row align-items-center">
          <motion.div 
            className="col-lg-6"
            variants={itemVariants}
          >
            <div className="hero-content">
              <motion.div 
                className="badge-container"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2 }}
              >
                <span className="hero-badge">
                  <i className="las la-rocket me-2"></i>
                  Innovation Hub
                </span>
              </motion.div>

              <motion.h1 
                className="hero-title"
                variants={itemVariants}
              >
                <span className="gradient-text">Custom Software</span>
                <span className="highlight-text">That Drives Growth</span>
              </motion.h1>

              <motion.p 
                className="hero-description"
                variants={itemVariants}
              >
                end-to-end software solutions. From startup MVPs to enterprise-grade systems, Your vision, our expertise – let's scale together.
              </motion.p>

              <motion.div 
                className="hero-features"
                variants={itemVariants}
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    key={activeFeature}
                    className="feature-card"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="feature-icon" style={{ color: features[activeFeature].color }}>
                      <i className={features[activeFeature].icon}></i>
                    </div>
                    <div className="feature-content">
                      <h3>{features[activeFeature].title}</h3>
                      <p>{features[activeFeature].description}</p>
                    </div>
                  </motion.div>
                </AnimatePresence>
              </motion.div>

              <motion.div 
                className="hero-buttons"
                variants={itemVariants}
              >
                <motion.a 
                  href="https://boarding.epicfinal.com"
                  className="btn btn-primary btn-glow"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Start Your Growth Journey
                  <i className="las la-rocket ms-2"></i>
                </motion.a>
                <motion.a 
                  href="/contact1" // Directs to the contact page
                  className="btn btn-outline-primary btn-hover-slide"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Contact Us
                  <i className="las la-envelope ms-2"></i> 
                </motion.a> 
              </motion.div>

              <motion.div 
                className="hero-stats"
                variants={itemVariants}
              >
                <div className="stat-item">
                  <motion.span 
                    className="stat-number"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                  >
                    300%
                  </motion.span>
                  <span className="stat-label">Productivity Boost</span>
                </div>
                <div className="stat-item">
                  <motion.span 
                    className="stat-number"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6 }}
                  >
                    360°
                  </motion.span>    
                  <span className="stat-label">Expert Support</span>
                </div>
                <div className="stat-item">
                  <motion.span 
                    className="stat-number"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.7 }}
                  >
                  </motion.span>
                  {/* <span className="stat-label">Expertise</span> */}
                </div>
              </motion.div>
            </div>
          </motion.div>

          <motion.div 
            className="col-lg-6"
            variants={itemVariants}
          >
            <div className="hero-image-wrapper">
              <motion.div 
                className="hero-image"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <img
                  src={require("../../assets/images/hero/02.png")}
                  alt="DevOps Solutions"
                  className="img-fluid floating"
                />
                <div className="image-overlay"></div>
              </motion.div>
              
              <motion.div 
                className="tech-bubble tech-bubble-1"
                animate={{
                  y: [0, -20, 0],
                  rotate: [0, 10, 0]
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <i className="lab la-docker"></i>
              </motion.div>

              <motion.div 
                className="tech-bubble tech-bubble-2"
                animate={{
                  y: [0, 20, 0],
                  rotate: [0, -10, 0]
                }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <i className="lab la-kubernetes"></i>
              </motion.div>

              <motion.div 
                className="tech-bubble tech-bubble-3"
                animate={{
                  y: [-10, 10, -10],
                  x: [-10, 10, -10]
                }}
                transition={{
                  duration: 6,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <i className="lab la-aws"></i>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="scroll-indicator">
        <motion.div
          animate={{
            y: [0, 10, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <i className="las la-mouse"></i>
          <span>Scroll to explore</span>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default HerosectionL4;
