import React from 'react';
import { motion } from 'framer-motion';
import './MultisecsL1.css';

function MultisecL1() {
  const logos = [
    {
      name: 'AWS',
      icon: 'lab la-aws',
      color: '#FF9900',
      description: 'Cloud Infrastructure'
    },
    {
      name: 'Jenkins',
      icon: 'las la-cogs',
      color: '#D24939',
      description: 'CI/CD Pipeline'
    },
    {
      name: 'Docker',
      icon: 'lab la-docker',
      color: '#2496ED',
      description: 'Containerization'
    },
    {
      name: 'GitHub',
      icon: 'lab la-github',
      color: '#181717',
      description: 'Version Control'
    },
   
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  return (
    <section className="tech-stack-section">
      <div className="container">
        <motion.div 
          className="row justify-content-center text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={containerVariants}
        >
          <div className="col-lg-8">
            <motion.div 
              className="section-header"
              variants={itemVariants}
            >
              <h2 className="section-title">
                <span className="gradient-text">Powered by</span>
                <span className="main-title">Industry-Leading Technologies</span>
              </h2>
              <p className="section-description">
                We leverage cutting-edge tools and technologies to deliver robust, scalable, and secure solutions for your business needs.
              </p>
            </motion.div>
          </div>
        </motion.div>

        <motion.div 
          className="tech-grid"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          {logos.map((logo, index) => (
            <motion.div
              key={index}
              className="tech-item"
              variants={itemVariants}
              whileHover={{ 
                scale: 1.05,
                boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1)'
              }}
            >
              <div className="tech-icon" style={{ color: logo.color }}>
                <i className={logo.icon}></i>
              </div>
              <div className="tech-content">
                <h3>{logo.name}</h3>
                <p>{logo.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}

export default MultisecL1;
