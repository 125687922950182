/**
 *  Main Menu Json
 */
export default  [
   {
      "menu_title": "Home",
      // "type": "subMenu",
      "path": "/",
      "icon": "home",
      // "child_routes": [
      //    {
      //       "path": "/",
      //       "menu_title": "Landing 1",
      //       "icon": "arrow_right_alt",
      //       "child_routes": null
      //    },
      //    {
      //       "path": "/index2",
      //       "menu_title": "Landing 2",
      //       "icon": "arrow_right_alt",
      //       "child_routes": null
      //    },
      //    {
      //       "path": "/index3",
      //       "menu_title": "Landing 3",
      //       "icon": "arrow_right_alt",
      //       "child_routes": null
      //    },
      //    {
      //       "path": "/index4",
      //       "menu_title": "Landing 4",
      //       "icon": "arrow_right_alt",
      //       "child_routes": null
      //    },
      // ]
   },
   {
      "menu_title": "Information",
      "path": "/",
      "mega": true,
      "icon": "party_mode",
      "type": "subMenu",
      "child_routes": [
         {
            "path": "/",
            "menu_title": "Company",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/about-us",
                  "menu_title": "About EpicFinal",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               // {
               //    "path": "/about-us2",
               //    "menu_title": "About US 2",
               //    "icon": "arrow_right_alt",
               //    "child_routes": null
               // },
               {
                  "path": "/career",
                  "menu_title": "Career",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               // {
               //    "path": "/career-single",
               //    "menu_title": "Career Single",
               //    "icon": "arrow_right_alt",
               //    "child_routes": null
               // },
            ]
         },
         {
            "path": "/",
            "menu_title": "Tech Docs",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               {
                  "path": "/blog_card",
                  "menu_title": "Blog",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
         //       {
         //          "path": "/blog_listing_1",
         //          "menu_title": "Blog Listing 1",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },
         //       {
         //          "path": "/blog_listing_2",
         //          "menu_title": "Blog Listing 2",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },
         //       {
         //          "path": "/blog-single",
         //          "menu_title": "Blog Single",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },
         //    ]
         // },
         // {
         //    "path": "/",
         //    "menu_title": "Contacts",
         //    "icon": "arrow_right_alt",
         //    "type": "childsubMenu",
         //    "child_routes": [
         //       {
         //          "path": "/contact1",
         //          "menu_title": "Contact 1",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },
         //       {
         //          "path": "/contact2",
         //          "menu_title": "Contact 2",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },

           ]
         },
         // {
         //    "path": "/",
         //    "menu_title": "Account",
         //    "icon": "arrow_right_alt",
         //    "type": "childsubMenu",
         //    "child_routes": [
         //       {
         //          "path": "/sign_in",
         //          "menu_title": "Sign In",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },
         //       {
         //          "path": "/sign_in_2",
         //          "menu_title": "Sign In 2",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },
         //       {
         //          "path": "/sign_up",
         //          "menu_title": "Sign Up",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },
         //       {
         //          "path": "/forgot_password",
         //          "menu_title": "Forgot Password",
         //          "icon": "arrow_right_alt",
         //          "child_routes": null
         //       },

         //    ]
         // },
         {
            "path": "/",
            "menu_title": "FAQs and Legal Guidelines",
            "icon": "arrow_right_alt",
            "type": "childsubMenu",
            "child_routes": [
               // {
               //    "path": "/pricing",
               //    "menu_title": "Pricing",
               //    "icon": "arrow_right_alt",
               //    "child_routes": null
               // },
               {
                  "path": "/faq",
                  "menu_title": "Faq",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               // {
               //    "path": "/maintenance",
               //    "menu_title": "Maintenance",
               //    "icon": "arrow_right_alt",
               //    "child_routes": null
               // },
               // {
               //    "path": "/coming_soon",
               //    "menu_title": "Coming Soon",
               //    "icon": "arrow_right_alt",
               //    "child_routes": null
               // },
               // {
               //    "path": "/error_404",
               //    "menu_title": "Error 404",
               //    "icon": "arrow_right_alt",
               //    "child_routes": null
               // },
               {
                  "path": "/privacy_policy",
                  "menu_title": "Privacy & Policy",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
               {
                  "path": "/terms_conditions",
                  "menu_title": "Terms & Conditions",
                  "icon": "arrow_right_alt",
                  "child_routes": null
               },
            ]
         },

      ],
   },
   // {
   //    "menu_title": "Shop",
   //    "type": "subMenu",
   //    "path": "/",
   //    "icon": "shop",
   //    "child_routes": [
   //       {
   //          "path": "/product-grid",
   //          "menu_title": "Product Grid",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/product-list",
   //          "menu_title": "Product List",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/product-single",
   //          "menu_title": "Product Single",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/cart",
   //          "menu_title": "Shoping Cart",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/checkout",
   //          "menu_title": "Checkout",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/order-complete",
   //          "menu_title": "Order Complete",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       }
   //    ]
   // },

   // {
   //    "menu_title": "Features",
   //    "type": "subMenu",
   //    "path": "/",
   //    "icon": "blog",
   //    "child_routes": [
   //       {
   //          "path": "/Accordion",
   //          "menu_title": "Accordion",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/blog",
   //          "menu_title": "Blog",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/Counter",
   //          "menu_title": "Counter",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/feature_box",
   //          "menu_title": "Feature Box",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/Price_table",
   //          "menu_title": "Price Table",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/team",
   //          "menu_title": "Team",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       },
   //       {
   //          "path": "/testimonial",
   //          "menu_title": "Testimonial",
   //          "icon": "arrow_right_alt",
   //          "child_routes": null
   //       }

   //    ]
   // },
      {
      "menu_title": "Solutions",
      "type": "subMenu",
      "path": "/",
      "icon": "blog",
      "child_routes": [ {
         "path": "/Development",
         "menu_title": "PrimeCode",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/Operations",
         "menu_title": "IT Operations Excellence",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/Security",
         "menu_title": "OpsSecurity",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/Consulting",
         "menu_title": "Consulting",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/Academy",
         "menu_title": "Learn Academy",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/Disaster_Recovery",
         "menu_title": "Disaster Recovery",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/Managed_Service",
         "menu_title": "ServerCare Plus",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/NOC",
         "menu_title": "Network Operation Center",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
      {
         "path": "/Private_Cloud",
         "menu_title": "Private Cloud",
         "icon": "arrow_right_alt",
         "child_routes": null
      },
   
   ]
      },
      {
                "menu_title": "Pricing ",
         "path": "/Calculator",
         "icon": "party_mode",  
      },
      {
         "menu_title": "Customer Service",
         "path": "https://epicfinal.freshdesk.com",
         "icon": "party_mode",  
      },
      
   //       "menu_title": "מכללת סייבר",
   //       "path": "https://college.epicfinal.com",
   //       "icon": "party_mode",  
   //    },
   //    {
   //       "menu_title": "מכללת סייבר",
   //       "path": "https://college.epicfinal.com",
   //       "icon": "party_mode",  
   //    },
   
   // {
   //    "menu_title": "לאתר בעברית",
   //    "path": "/he",
   //    "icon": "party_mode",  
   // },

]
