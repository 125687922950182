import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './FeatureL2.css';
import a from './cloud-done.png';
import b from './TI-DONE.png';
import c from './COLLEGE-DONE.png';
import d from './Disaster.png';
import e from './Managed.png';
import f from './Monitoring.png';
import g from './automation.png';
import h from './IT.png';
import i from './GA.PNG';

const features = [
  { 
    icon: 'las la-search',
    background: "var(--feature-color-1)",
    title: "תכנון ומחקר",
    description: "מחקרים וניתוח נתונים תוך תכנון מושכל ואסטרטגי להצלחה המרבית שלנו",
    delay: 0.2,
    path: "/Consulting"
  },
  { 
    icon: 'las la-code',
    background: "var(--feature-color-2)",
    title: "פיתוח",
    description: "אנחנו מתמחים בהפיכת הרעיונות שלכם למציאות באמצעות טכנולוגיות מתקדמות",
    delay: 0.3,
    path: "/Development"
  },
  { 
    icon: 'las la-cogs',
    background: "var(--feature-color-3)",
    title: "תפעול",
    description: "אנחנו מנהלים את התפעול ואת הצמיחה של הרעיון שלכם, דואגים למסע חלק וביצועים גבוהים",
    delay: 0.4,
    path: "/Operations"
  },
  { 
    icon: 'las la-plug',
    background: "var(--feature-color-4)",
    title: "אינטגרציות",
    description: "חיבור לפעולות שלך על ידי אינטגרציה חלקה של מערכות שונות, יוצרים תהליך עבודה יעיל ומאורגן",
    delay: 0.5,
    path: "/Operations"
  },
  { 
    icon: 'las la-shield-alt',
    background: "var(--feature-color-5)",
    title: "אבטחה",
    description: "עם אבטחה בראש סדר העדיפויות שלנו, אנו מתכננים כל נקודת מגע עם שיקול דעת מירבי",
    delay: 0.6,
    path: "/Security"
  },
  { 
    icon: 'las la-headset',
    background: "var(--feature-color-6)",
    title: "ייעוץ ותמיכה",
    description: "אנחנו משמשים כשותפי ייעוץ ותמיכה, מציעים פתרונות מומחים כשאתם צריכים",
    delay: 0.7,
    path: "/Consulting"
  }
];

const serviceImages = [
  { img: c, alt: "שירותי ענן לעסקים", category: "cloud" },
  { img: b, alt: "תשתיות טכנולוגיות", category: "infrastructure" },
  { img: a, alt: "תכנון ופיתוח מערכות", category: "development" },
  { img: d, alt: "שירותי התאוששות מאסון", category: "disaster-recovery" },
  { img: e, alt: "ניהול שירותים", category: "managed-services" },
  { img: f, alt: "שירותי ניטור ובקרה", category: "monitoring" },
  { img: g, alt: "אוטומציות", category: "automation" },
  { img: h, alt: "IT שירותי", category: "it-services" },
  { img: i, alt: "פיתוח תוכנה SAAS", category: "saas" }
];

function FeatureL2he() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <section className="feature-section rtl-section">
      <motion.div 
        className="cloud-announcement"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.4 }}
        style={{
          background: 'linear-gradient(135deg, #1a2980 0%, #26d0ce 100%)',
          color: 'white',
          padding: '1.2rem',
          textAlign: 'center',
          marginBottom: '2rem',
          borderRadius: '12px',
          boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: window.innerWidth <= 768 ? '8px' : '12px',
          maxWidth: '900px',
          margin: '0 auto 2rem auto',
          width: 'calc(100% - 2rem)'
        }}
      >
        <div style={{
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '50%',
          padding: window.innerWidth <= 768 ? '8px' : '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <i className="las la-cloud" style={{ fontSize: window.innerWidth <= 768 ? '20px' : '24px' }}></i>
        </div>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px',
          flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
          width: window.innerWidth <= 768 ? '100%' : 'auto'
        }}>
          <motion.span
            initial={{ scale: 1 }}
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 2, repeat: Infinity, repeatDelay: 3 }}
            style={{
              background: '#ff4757',
              color: 'white',
              padding: '4px 12px',
              borderRadius: '20px',
              fontSize: window.innerWidth <= 768 ? '0.8rem' : '0.9rem',
              fontWeight: 'bold'
            }}
          >
            חדש!
          </motion.span>
          <span style={{ 
            fontSize: window.innerWidth <= 768 ? '1rem' : '1.1rem', 
            fontWeight: '500',
            lineHeight: window.innerWidth <= 768 ? '1.4' : 'inherit'
          }}>
            שירותי ענן היברידי זמינים כעת בשני מתקנים באירופה
          </span>
        </div>
        {window.innerWidth > 768 && (
          <motion.div
            whileHover={{ x: 5 }}
            style={{
              background: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '50%',
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <i className="las la-arrow-left" style={{ fontSize: '20px' }}></i>
          </motion.div>
        )}
      </motion.div>

      <Container fluid={window.innerWidth <= 768}>
        <Row className="justify-content-center">
          <Col lg={8} md={10} className="text-center px-3">
            <motion.div
              className="section-header modern-header"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-50px" }}
              transition={{ duration: 0.6 }}
              style={{
                padding: window.innerWidth <= 768 ? '1rem 0' : '2rem 0'
              }}
            >
              <span className="section-tag modern-tag" style={{
                fontSize: window.innerWidth <= 768 ? '0.9rem' : '1rem',
                padding: window.innerWidth <= 768 ? '0.5rem 1rem' : '0.75rem 1.5rem'
              }}>
                <span style={{ color: '#4a90e2', fontWeight: 'bold', fontSize: '1.5rem', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}><i className="las la-rocket"></i> פתרונות טכנולוגיים מתקדמים</span>
              </span>
              <h2 className="section-title modern-title" style={{
                fontSize: window.innerWidth <= 768 ? '1.8rem' : '2.5rem',
                lineHeight: window.innerWidth <= 768 ? '1.3' : '1.5',
                marginBottom: '1rem'
              }}>
                <div className="gradient-text modern-gradient">מובילים את העתיד הדיגיטלי</div>
                <div className="gradient-text-secondary">עם פתרונות חדשניים</div>
                <div className="highlight-box" style={{
                  padding: window.innerWidth <= 768 ? '0.3rem' : '0.5rem',
                  margin: window.innerWidth <= 768 ? '0.3rem 0' : '0.5rem 0'
                }}>המותאמים במיוחד עבורכם</div>
              </h2>
              
              <div className="modern-description" style={{
                fontSize: window.innerWidth <= 768 ? '0.9rem' : '1rem',
                padding: window.innerWidth <= 768 ? '0.5rem' : '1rem'
              }}>
                <div className="highlight modern-highlight" style={{
                  margin: window.innerWidth <= 768 ? '0.3rem 0' : '0.5rem 0'
                }}>
                  <i className="las la-lightbulb"></i>
                  מומחים בהפיכת חזון למציאות דיגיטלית
                </div>
                
                <div className="feature-text" style={{
                  margin: window.innerWidth <= 768 ? '0.5rem 0' : '1rem 0'
                }}>
                  <span className="text-emphasis">אנחנו מלווים אתכם בכל שלב</span>
                  <br />
                  מיזמים בתחילת דרכם ועד עסקים מבוססים
                </div>
                
                <div className="feature-box" style={{
                  padding: window.innerWidth <= 768 ? '0.75rem' : '1rem',
                  margin: window.innerWidth <= 768 ? '0.5rem 0' : '1rem 0',
                  flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                  gap: window.innerWidth <= 768 ? '0.5rem' : '1rem'
                }}>
                  <i className="las la-code"></i>
                  <span>פיתוח מהיר ומקצועי</span>
                  {window.innerWidth > 768 && <i className="las la-arrow-right mx-2"></i>}
                  <span>פתרונות בהתאמה אישית</span>
                </div>
                
                <div className="feature-box-secondary" style={{
                  padding: window.innerWidth <= 768 ? '0.75rem' : '1rem',
                  margin: window.innerWidth <= 768 ? '0.5rem 0' : '1rem 0',
                  flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                  gap: window.innerWidth <= 768 ? '0.5rem' : '1rem'
                }}>
                  <i className="las la-shield-alt"></i>
                  <span>אבטחה מתקדמת</span>
                  {window.innerWidth > 768 && <i className="las la-plus mx-2"></i>}
                  <span>תשתיות טכנולוגיות חדישות</span>
                </div>
                
                <div className="feature-text-large" style={{
                  fontSize: window.innerWidth <= 768 ? '1.2rem' : '1.5rem',
                  margin: window.innerWidth <= 768 ? '1rem 0' : '2rem 0'
                }}>
                  <span className="text-gradient">הכל תחת קורת גג אחת</span> להצלחה הדיגיטלית שלכם
                </div>

                <div className="platform-onboarding" style={{
                  padding: window.innerWidth <= 768 ? '1rem 0' : '2rem 0'
                }}>
                  <h3 className="text-center mb-4" style={{
                    fontSize: window.innerWidth <= 768 ? '1.3rem' : '1.5rem'
                  }}>תהליך העבודה שלנו</h3>
                  
                  <div className="onboarding-steps" style={{
                    display: 'grid',
                    gridTemplateColumns: window.innerWidth <= 768 ? '1fr' : 'repeat(auto-fit, minmax(200px, 1fr))',
                    gap: window.innerWidth <= 768 ? '1rem' : '2rem',
                    padding: window.innerWidth <= 768 ? '0.5rem' : '1rem'
                  }}>
                    <div className="step">
                      <div className="step-number">1</div>
                      <div className="step-icon">🚀</div>
                      <h4>צור פרויקט חדש</h4>
                      <p>התחל מיד, בקלות ובמהירות</p>
                    </div>
                    
                    <div className="step">
                      <div className="step-number">2</div>
                      <div className="step-icon">🎯</div>
                      <h4>בחר את המסלול שלך</h4>
                      <p>קבל ייעוץ מומחה או התחל בהערכה חכמה</p>
                    </div>
                    
                    <div className="step">
                      <div className="step-number">3</div>
                      <div className="step-icon">📅</div>
                      <h4>קבע פגישה</h4>
                      <p>נתאם יחד את המטרות והדרישות</p>
                    </div>
                    
                    <div className="step">
                      <div className="step-number">4</div>
                      <div className="step-icon">📊</div>
                      <h4>עקוב אחר ההתקדמות</h4>
                      <p>צפה בכל שלב דרך לוח הבקרה שלך</p>
                    </div>
                    
                    <div className="step">
                      <div className="step-number">5</div>
                      <div className="step-icon">🎉</div>
                      <h4>השלמת הפרויקט</h4>
                      <p>קבל את קוד המקור או האישורים בקלות</p>
                    </div>
                  </div>
                  
                  <div className="text-center mt-4">
                    <a href="https://boarding.epicfinal.com/register" 
                       className="platform-btn primary"
                       target="_blank"
                       rel="noopener noreferrer">
                      <span className="button-text">התחל עכשיו בפלטפורמה</span>
                      <i className="las la-arrow-left"></i>
                    </a>
                    <a href="https://meetings.hubspot.com/viacheslav-seifer" 
                       className="platform-btn green"
                       target="_blank"
                       rel="noopener noreferrer">
                      <span className="button-text">קבע פגישה</span>
                      <i className="las la-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>
          </Col>
        </Row>

        <motion.div
          className="features-grid"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-50px" }}
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card"
              variants={itemVariants}
              whileHover={{ y: -5, transition: { duration: 0.2 } }}
            >
              <Link to={feature.path} className="feature-link">
                <div className="feature-icon-wrapper" style={{ background: feature.background }}>
                  <i className={feature.icon}></i>
                </div>
                <div className="feature-content">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              </Link>
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          className="platform-cta"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-50px" }}
          transition={{ duration: 0.6 }}
        >
          <Row>
            <Col lg={12} className="text-center">
              <h2 className="highlight-title">הפתרונות שלנו</h2>
            </Col>
          </Row>
          
          <motion.div
            className="service-images"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-50px" }}
          >
            <Row>
              {serviceImages.map((service, index) => (
                <Col md={4} className="text-center mb-4" key={index}>
                  <motion.div
                    variants={itemVariants}
                    whileHover={{ y: -10, transition: { duration: 0.2 } }}
                  >
                    <img 
                      src={service.img} 
                      alt={service.alt} 
                      className="img-fluid rounded shadow-sm"
                      loading="lazy"
                    />
                    {service.category === "saas" && (
                      <motion.div
                        className="mt-3"
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Link to="/Pricing-Calculator" className="platform-btn primary">
                          המשך למחשבון עלות
                          <i className="las la-arrow-left"></i>
                        </Link>
                      </motion.div>
                    )}
                  </motion.div>
                </Col>
              ))}
            </Row>
          </motion.div>
        </motion.div>
      </Container>
    </section>
  );
}

export default FeatureL2he;
