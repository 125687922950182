import React, { useEffect, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { motion, AnimatePresence } from 'framer-motion';
import './Service.css';
import './animations.css';

function Service() {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersect = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.querySelectorAll('.fade-up').forEach((el, index) => {
            setTimeout(() => {
              el.classList.add('visible');
            }, index * 100);
          });
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const carouselImages = [
    {
      image: require("../../assets/images/app/01.jpg"),
      alt: "Service Image 1",
      title: "Modern Infrastructure"
    },
    {
      image: require("../../assets/images/app/02.jpg"),
      alt: "Service Image 2",
      title: "Cloud Solutions"
    },
    {
      image: require("../../assets/images/app/03.jpg"),
      alt: "Service Image 3",
      title: "Security First"
    }
  ];

  const features = [
    {
      icon: 'las la-rocket',
      bgColor: 'rgba(66, 133, 244, 0.1)',
      title: 'Fast Performance',
      description: 'Lightning-fast response times with optimized infrastructure and caching.',
      link: '/performance'
    },
    {
      icon: 'las la-shield-alt',
      bgColor: 'rgba(52, 168, 83, 0.1)',
      title: 'Enhanced Security',
      description: 'Enterprise-grade security with advanced threat protection.',
      link: '/security'
    },
    {
      icon: 'las la-cloud',
      bgColor: 'rgba(251, 188, 4, 0.1)',
      title: 'Cloud Solutions',
      description: 'Scalable cloud infrastructure that grows with your business.',
      link: '/cloud'
    },
    {
      icon: 'las la-clock',
      bgColor: 'rgba(234, 67, 53, 0.1)',
      title: '24/7 Support',
      description: 'Round-the-clock expert support whenever you need assistance.',
      link: '/support'
    },
  ];

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.section 
      className="service-section" 
      ref={sectionRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-12 order-lg-1 mb-5 mb-lg-0">
            <motion.div 
              className="service-carousel"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <OwlCarousel
                className="owl-theme"
                items={1}
                autoplay={true}
                dots={true}
                loop={true}
                nav={true}
                margin={20}
                smartSpeed={1000}
                animateIn="fadeIn"
                animateOut="fadeOut"
                navText={[
                  '<i class="las la-arrow-left"></i>',
                  '<i class="las la-arrow-left"></i>'
                ]}
                responsive={{
                  0: { items: 1 },
                  768: { items: 1 }
                }}
              >
                {carouselImages.map((item, index) => (
                  <div key={index} className="carousel-image">
                    <img 
                      className="img-fluid" 
                      src={item.image} 
                      alt={item.alt}
                      loading="lazy"
                    />
                    <motion.div 
                      className="carousel-caption"
                      initial={{ opacity: 0, y: 20 }}
                      whileHover={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <h3>{item.title}</h3>
                    </motion.div>
                  </div>
                ))}
              </OwlCarousel>
            </motion.div>
          </div>

          <div className="col-lg-8 col-12">
            <motion.div 
              className="section-title"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <motion.span 
                className="badge bg-primary-subtle text-primary mb-3"
                whileHover={{ scale: 1.05 }}
              >
                <i className="las la-star me-2"></i>
                Our Services
              </motion.span>
              <h2>
                <span className="font-w-4">Transforming Business with</span>
                <br />
                <strong className="text-gradient">Innovative Solutions</strong>
              </h2>
              <p className="lead text-muted mt-4">
                Empowering your digital journey with cutting-edge technology and expert support.
              </p>
            </motion.div>

            <div className="feature-grid">
              <AnimatePresence>
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    className="service-card"
                    variants={cardVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ 
                      duration: 0.5,
                      delay: index * 0.1,
                      ease: "easeOut"
                    }}
                    whileHover={{ 
                      y: -10,
                      transition: { duration: 0.2 }
                    }}
                  >
                    <motion.div 
                      className="service-icon"
                      style={{ backgroundColor: feature.bgColor }}
                      whileHover={{ 
                        scale: 1.1,
                        rotate: 5,
                        transition: { duration: 0.2 }
                      }}
                    >
                      <i className={feature.icon}></i>
                    </motion.div>
                    <h3 className="service-title">{feature.title}</h3>
                    <p className="service-description">{feature.description}</p>
                    <motion.a 
                      href={feature.link} 
                      className="btn btn-link text-primary"
                      whileHover={{ x: 5 }}
                      transition={{ duration: 0.2 }}
                    >
                      Explore Service
                      <i className="las la-arrow-right ms-2"></i>
                    </motion.a>
                    <div className="card-decoration"></div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}

export default Service;