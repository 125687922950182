import React from 'react';
import './NotificationBanner.css';

/**
 * NotificationBanner component displays an elegant notification at the top of the page
 * @returns {JSX.Element} The notification banner component
 */
const NotificationBanner = () => {
  return (
    <div className="notification-banner">
      <div className="notification-content">
        <div className="notification-icon">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2L2 7L12 12L22 7L12 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 17L12 22L22 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 12L12 17L22 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
        <span className="notification-text">
        Hybrid Cloud Now Available Across Two Facilities in Europe
        </span>
        <div className="notification-status">
          <span className="status-dot"></span>
          <span className="status-text">Available Now</span>
        </div>
        {/* <button className="learn-more-btn">Learn More</button> */}
      </div>
    </div>
  );
};

export default NotificationBanner; 