import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Cons1.css';

const ConsultingSolutionsOffer = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);

    return (
        <section className="consulting-solutions-offer py-5">
            <div className="container">
                {/* Heading and Intro */}
                <div className="row justify-content-center mb-5" data-aos="fade-up">
                    <div className="col-lg-8 text-center">
                        <span className="badge bg-primary mb-3">Expert Consulting Services</span>
                        <h2 className="display-4 fw-bold mb-4">Comprehensive 360° Consulting</h2>
                        <div className="section-divider mb-4"></div>
                        <p className="lead text-muted">
                            Empower your organization with our full-spectrum consulting services. We specialize in guiding 
                            emerging and established businesses through technical, operational, and strategic challenges.
                        </p>
                    </div>
                </div>

                {/* Technology & Software Strategy */}
                <div className="row align-items-center mb-5 service-card" data-aos="fade-right">
                    <div className="col-md-6">
                        <div className="service-content p-4">
                            <div className="service-icon mb-4">
                                <i className="fas fa-laptop-code"></i>
                            </div>
                            <h3 className="h4 fw-bold mb-3">Technology & Software Strategy</h3>
                            <p className="text-muted">
                                Our experts evaluate your current systems and processes to design tailored software 
                                and technology strategies. From initial planning to seamless implementation, 
                                we ensure your digital infrastructure evolves in lockstep with your business goals.
                            </p>
                            {/* <a href="#" className="btn btn-outline-primary mt-3">Learn More</a> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-image">
                            <div className="image-placeholder bg-light rounded-3 p-4 text-center">
                                <i className="fas fa-chart-network display-1 text-primary"></i>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Startups & SMB Growth */}
                <div className="row align-items-center mb-5 service-card" data-aos="fade-left">
                    <div className="col-md-6 order-md-2">
                        <div className="service-content p-4">
                            <div className="service-icon mb-4">
                                <i className="fas fa-rocket"></i>
                            </div>
                            <h3 className="h4 fw-bold mb-3">Startups & SMB Growth</h3>
                            <p className="text-muted">
                                We provide specialized guidance to help entrepreneurs, startups, and small to mid-sized 
                                businesses scale effectively. Whether you are refining a product roadmap, optimizing 
                                funding strategies, or enhancing market positioning, we deliver impactful insights.
                            </p>
                            {/* <a href="#" className="btn btn-outline-primary mt-3">Learn More</a> */}
                        </div>
                    </div>
                    <div className="col-md-6 order-md-1">
                        <div className="service-image">
                            <div className="image-placeholder bg-light rounded-3 p-4 text-center">
                                <i className="fas fa-lightbulb display-1 text-primary"></i>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 360° Consulting Approach */}
                <div className="row mb-5" data-aos="fade-up">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="approach-card text-center p-5 rounded-3 shadow-sm">
                            <div className="approach-icon mb-4">
                                <i className="fas fa-sync-alt"></i>
                            </div>
                            <h3 className="h4 fw-bold mb-3">360° Consulting Approach</h3>
                            <p className="text-muted">
                                Our holistic methodology encompasses every angle of your business—from strategic 
                                planning and process optimization to organizational development and digital transformation. 
                                We partner with you to identify gaps, implement efficiencies, and foster innovation.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Call to Action */}
                <div className="row justify-content-center" data-aos="zoom-in">
                    <div className="col-lg-8">
                        <div className="cta-card text-center p-5 rounded-3 bg-primary text-white">
                            <h3 className="h4 fw-bold mb-3">Ready to Transform Your Business?</h3>
                            <p className="lead mb-4">
                                Discover how our comprehensive consulting solutions can drive sustainable success for you.
                            </p>
                            <a href="contact1" className="btn btn-light btn-lg">
                                Get Started <i className="fas fa-arrow-right ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ConsultingSolutionsOffer;
