import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './FeatureL2.css';

function FeatureL2() {
  const navigate = useNavigate();
  
  const features = [
    {
      icon: 'las la-search',
      background: "var(--feature-color-1)",
      title: "Plan And Research",
      description: "We'll collaborate with you to shape and research your ideas, ensuring a well-informed and strategic planning process.",
      delay: 0.2,
      path: "/Consulting"
    },
    {
      icon: 'las la-code',
      background: "var(--feature-color-2)",
      title: "Development",
      description: "We specialize in transforming your ideas into reality using cutting-edge technologies.",
      delay: 0.3,
      path: "/Development"
    },
    {
      icon: 'las la-cogs',
      background: "var(--feature-color-3)",
      title: "Operations",
      description: "We oversee both the operational intricacies and scalable growth of your idea, ensuring a seamless and high-performance journey.",
      delay: 0.4,
      path: "/Operations"
    },
    {
      icon: 'las la-plug',
      background: "var(--feature-color-4)",
      title: "Integrations",
      description: "We bring synergy to your operations by seamlessly integrating diverse systems, creating a cohesive and efficient workflow for your business.",
      delay: 0.5,
      path: "/Operations"
    },
    {
      icon: 'las la-shield-alt',
      background: "var(--feature-color-5)",
      title: "Security",
      description: "With security at the forefront of our approach, we design every aspect with vigilant consideration, embedding protective measures into the very fabric of your digital environment.",
      delay: 0.6,
      path: "/Security"
    },
    {
      icon: 'las la-headset',
      background: "var(--feature-color-6)",
      title: "Consulting and Support",
      description: "We serve as your dependable support and consulting partner, offering expertise and assistance whenever you require guidance or strategic insights.",
      delay: 0.7,
      path: "/Consulting"
    }
  ];

  return (
    <section className="feature-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <motion.div
              className="section-header"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.6 }}
            >
              <span className="section-tag">Streamlined Development</span>
              <h2 className="section-title">
                End-to-End Solutions
                <span className="gradient-text">DevOps Excellence</span>
              </h2>
              <p className="section-description">
                Accelerate your business growth with our comprehensive software development services. 
                We combine cutting-edge technology with proven methodologies to deliver solutions that scale.
              </p>
            </motion.div>
          </Col>
        </Row>

        <Row className="align-items-center mb-5">
          <Col lg={6}>
            <motion.div
              className="cicd-image-wrapper"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <div className="cicd-visualization">
                <div className="pipeline-step">
                  <i className="las la-chart-line"></i>
                  <span>Monitor</span>
                </div>
                <div className="pipeline-arrow">
                  <i className="las la-arrow-left"></i>
                </div>
                <div className="pipeline-step">
                  <i className="las la-rocket"></i>
                  <span>Deploy</span>
                </div>
                <div className="pipeline-arrow">
                  <i className="las la-arrow-left"></i>
                </div>
                <div className="pipeline-step">
                  <i className="las la-box"></i>
                  <span>Build</span>
                </div>
                <div className="pipeline-arrow">
                  <i className="las la-arrow-left"></i>
                </div>
                <div className="pipeline-step">
                  <i className="las la-vial"></i>
                  <span>Test</span>
                </div>
                <div className="pipeline-arrow">
                  <i className="las la-arrow-left"></i>
                </div>
                <div className="pipeline-step">
                  <i className="las la-code"></i>
                  <span>Code</span>
                </div>
              </div>
              <div className="tech-badges">
                <motion.span 
                  className="tech-badge"
                  whileHover={{ scale: 1.1 }}
                >
                  <i className="lab la-docker"></i>
                </motion.span>
                <motion.span 
                  className="tech-badge"
                  whileHover={{ scale: 1.1 }}
                >
                  <i className="lab la-aws"></i>
                </motion.span>
                <motion.span 
                  className="tech-badge"
                  whileHover={{ scale: 1.1 }}
                >
                  <i className="lab la-github"></i>
                </motion.span>
              </div>
            </motion.div>
          </Col>
          <Col lg={6}>
            <motion.div
              className="feature-highlights"
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <h3 className="highlight-title">Boosted Productivity</h3>
              <ul className="highlight-list">
                <li><i className="las la-check-circle"></i> Automated CI/CD Pipeline</li>
                <li><i className="las la-check-circle"></i> Containerized Applications</li>
                <li><i className="las la-check-circle"></i> Cloud-Native Architecture</li>
                <li><i className="las la-check-circle"></i> Scalable Infrastructure</li>
              </ul>
            </motion.div>
          </Col>
        </Row>

        <motion.div 
          className="features-grid"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card"
              onClick={() => navigate(feature.path)}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: feature.delay
                  }
                }
              }}
              whileHover={{ 
                scale: 1.02,
                boxShadow: "0 20px 40px rgba(0, 0, 0, 0.1)"
              }}
              transition={{ duration: 0.2 }}
            >
              <div className="feature-icon-wrapper" style={{ background: feature.background }}>
                <i className={feature.icon}></i>
              </div>
              <div className="feature-content">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
              <div className="feature-arrow">
                {/* <i className="las la-arrow-right"></i> */}
              </div>
            </motion.div>
          ))}
        </motion.div>

        <Row className="justify-content-center mt-5">
          <Col lg={10} className="text-center">
            <motion.div
              className="platform-cta"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <div className="platform-badge">
                <i className="las la-rocket"></i>
                <span>Instant Project Setup</span>
              </div>
              <h2 className="platform-title">
                Start Building Your Next Project with
                <span className="gradient-text"> epicfinal</span>
              </h2>
              <p className="platform-description">
                Launch your project in minutes with our automated platform. 
                Get instant access to our full suite of development tools and expert support.
              </p>
              <div className="platform-features">
                <div className="platform-feature">
                  <i className="las la-bolt"></i>
                  <span>Instant Setup</span>
                </div>
                <div className="platform-feature">
                  <i className="las la-code-branch"></i>
                  <span>CI/CD Ready</span>
                </div>
                <div className="platform-feature">
                  <i className="las la-shield-alt"></i>
                  <span>Secure by Default</span>
                </div>
                <div className="platform-feature">
                  <i className="las la-clock"></i>
                  <span>Support & Consulting</span>
                </div>
              </div>
              <motion.div className="platform-actions">
                <motion.a 
                  href="https://boarding.epicfinal.com"
                  className="platform-btn primary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Launch Your Project
                  <i className="las la-rocket"></i>
                </motion.a>
                <motion.a 
                  href="https://boarding.epicfinal.com/register"
                  className="platform-btn secondary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Register Now
                  <i className="las la-play-circle"></i>
                </motion.a>
              </motion.div>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FeatureL2;
