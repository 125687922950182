import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const KeywordContainer = styled.div`
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  color: white;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }

  @media (max-width: 320px) {
    padding: 3rem 0.75rem;
  }
`;

const GlowingBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(56, 189, 248, 0.15), transparent 25%),
    radial-gradient(circle at 80% 80%, rgba(99, 102, 241, 0.15), transparent 25%);
`;

const KeywordGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0;
  }

  @media (max-width: 320px) {
    gap: 1.5rem;
  }

  @media (min-width: 769px) and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const KeywordCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 3rem 2rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-align: left;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
  }

  @media (max-width: 320px) {
    padding: 1.5rem 1.25rem;
    border-radius: 16px;
  }

  &:hover {
    transform: translateY(-8px);
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 
      0 20px 40px rgba(0, 0, 0, 0.3),
      0 0 50px rgba(99, 102, 241, 0.1);
  }

  @media (max-width: 768px) {
    &:hover {
      transform: translateY(-4px);
    }
  }

  @media (max-width: 320px) {
    &:hover {
      transform: translateY(-2px);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, 
      ${props => props.gradientStart || '#38bdf8'} 0%,
      ${props => props.gradientEnd || '#818cf8'} 100%
    );
    opacity: 0.8;
  }
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 2rem;
  background: linear-gradient(135deg, #38bdf8 0%, #818cf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2;
  min-height: 2.4em;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 1.75rem;
    min-height: auto;
    margin-bottom: 1rem;
  }

  @media (max-width: 320px) {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.7;
  color: #cbd5e1;
  margin: 0;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.6;
  }

  @media (max-width: 320px) {
    font-size: 0.9375rem;
    line-height: 1.5;
  }
`;

const Decorator = styled.div`
  width: 60px;
  height: 60px;
  margin-bottom: 2rem;
  background: linear-gradient(135deg,
    ${props => props.gradientStart || '#38bdf8'} 0%,
    ${props => props.gradientEnd || '#818cf8'} 100%
  );
  border-radius: 16px;
  opacity: 0.9;
  transform: rotate(-10deg);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 320px) {
    width: 40px;
    height: 40px;
    margin-bottom: 1.25rem;
    border-radius: 12px;
  }
`;

const MarketingKeywords = () => {
  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 }
  };

  const keywords = [
    {
      title: "No Vendor Lock-in",
      description: "Complete freedom and flexibility with your technology choices. Your data, your rules, always.",
      gradientStart: '#38bdf8',
      gradientEnd: '#818cf8'
    },
    {
      title: "Data-Driven Decisions",
      description: "Transform raw data into actionable insights. Make informed choices backed by real analytics.",
      gradientStart: '#8b5cf6',
      gradientEnd: '#d946ef'
    },
    {
      title: "Focused on Cost Efficiency",
      description: "Maximize your ROI with optimized solutions that deliver value without unnecessary expenses.",
      gradientStart: '#14b8a6',
      gradientEnd: '#3b82f6'
    }
  ];

  return (
    <KeywordContainer>
      <GlowingBackground />
      <KeywordGrid>
        {keywords.map((keyword, index) => (
          <motion.div
            key={index}
            initial="hidden"
            animate="visible"
            variants={cardVariants}
            transition={{ 
              duration: 0.7,
              delay: index * 0.2,
              ease: [0.23, 1, 0.32, 1]
            }}
          >
            <KeywordCard gradientStart={keyword.gradientStart} gradientEnd={keyword.gradientEnd}>
              <Decorator gradientStart={keyword.gradientStart} gradientEnd={keyword.gradientEnd} />
              <CardContent>
                <Title>{keyword.title}</Title>
                <Description>{keyword.description}</Description>
              </CardContent>
            </KeywordCard>
          </motion.div>
        ))}
      </KeywordGrid>
    </KeywordContainer>
  );
};

export default MarketingKeywords; 