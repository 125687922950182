import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './AboutUsL2.css';
// Import images directly
import aboutImage from '../../assets/images/about/04.png';

function AboutUsL2() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: "50px"
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { 
        duration: 0.6,
        staggerChildren: 0.2,
        ease: "easeOut"
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const imageVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const features = [
    {
      text: "Full-Stack Development: From MVP to Enterprise Solutions",
      color: "var(--primary-gradient-start)",
      icon: "las la-code",
      description: "Building scalable applications with modern technologies"
    },
    {
      text: "Advanced Cybersecurity & Protection Systems",
      color: "var(--primary-gradient-end)",
      icon: "las la-shield-alt",
      description: "Ensuring your digital assets remain secure 24/7"
    },
    {
      text: "DevOps Excellence & Cloud Infrastructure",
      color: "var(--accent-color)",
      icon: "las la-server",
      description: "Optimizing deployment and scaling processes"
    }
  ];

  return (
    <section className="about-section" ref={ref}>
      <div className="container">
        <motion.div 
          className="row justify-content-center text-center mb-5"
          initial="hidden"
          animate={controls}
          variants={containerVariants}
        >
          <div className="col-lg-8">
            <motion.span 
              className="subtitle"
              variants={itemVariants}
            >
              About Our Approach
            </motion.span>
            <motion.h2 
              className="title"
              variants={itemVariants}
            >
              <span>Transforming Ideas into</span>
              <span className="gradient-text">Digital Excellence</span>
            </motion.h2>
          </div>
        </motion.div>

        <div className="row align-items-center">
          {/* Content Column */}
          <div className="col-12 col-lg-6 mb-5 mb-lg-0">
            <motion.div
              className="content-wrapper"
              initial="hidden"
              animate={controls}
              variants={containerVariants}
            >
              <motion.p 
                className="description"
                variants={itemVariants}
              >
                We're your comprehensive software engineering partner, delivering excellence in Development, Security, and Operations. From startups to enterprises, we transform your vision into powerful digital solutions with our end-to-end expertise.
              </motion.p>

              <div className="features-grid">
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    className="feature-item"
                    variants={itemVariants}
                    custom={index}
                    whileHover={{ 
                      scale: 1.02,
                      transition: { duration: 0.2 }
                    }}
                  >
                    <div className="feature-icon" style={{ backgroundColor: feature.color }}>
                      <i className={feature.icon}></i>
                    </div>
                    <div className="feature-content">
                      <h4>{feature.text}</h4>
                      <p>{feature.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>

              <motion.div
                className="action-wrapper"
                variants={itemVariants}
              >
                <motion.a 
                  href="https://boarding.epicfinal.com" 
                  className="btn-custom"
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                  transition={{ duration: 0.2 }}
                >
                  Create Your Project In Seconds
                  <i className="las la-arrow-right"></i>
                </motion.a>
              </motion.div>
            </motion.div>
          </div>

          {/* Image Column */}
          <div className="col-12 col-lg-6">
            <motion.div 
              className="image-container"
              initial="hidden"
              animate={controls}
              variants={containerVariants}
            >
              <motion.div 
                className="main-image-wrapper"
                variants={imageVariants}
                whileHover={{ 
                  scale: 1.01,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="image-overlay"></div>
                <img 
                  src={aboutImage}
                  alt="Software Engineering Solutions" 
                  className="main-image"
                  loading="lazy"
                />
              </motion.div>

              <motion.div 
                className="stats-card"
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="stats-content">
                  <div className="stats-icon">
                    <i className="las la-rocket"></i>
                  </div>
                  <div className="stats-info">
                    <h3>Enterprise</h3>
                    <p>Grade Solutions</p>
                  </div>
                </div>
              </motion.div>

              <motion.div 
                className="experience-badge"
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <span className="years">All in One</span>
                <span className="text">Expert Support</span>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUsL2;
